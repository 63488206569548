import { graphql } from 'gatsby'
import ProjectPage from '../components/ProjectPage'

export default ProjectPage

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    wordpressWpProject(id: { eq: $id }) {
      title
      content
      acf {
        header_image {
          localFile {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        images {
          localFile {
            childImageSharp {
              fixed(width: 1376, quality: 95) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
        is_new_project
        used_materials
        year
        description
      }
    }
  }
`
