import React, { useContext, useCallback, useEffect } from 'react'
import Img from 'gatsby-image'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'
import style from './ProjectPage.module.scss'
import GlobalContext from '../GlobalContext'
import ContactForm from '../ContactForm'

const imagesCols = (pair = []) =>
  pair &&
  pair.length > 0 && (
    <>
      {pair.map(image => (
        <div className="w-full md:w-1/2 p-4">
          <Img
            fixed={image}
            objectFit="contain"
            className={style.contentImage}
            backgroundColor="#ebebec"
          />
        </div>
      ))}
    </>
  )

const ProjectPage = ({
  data: {
    wordpressWpProject: {
      title,
      acf: {
        header_image,
        images,
        description,
        is_new_project,
        used_materials,
        year,
      },
    },
  },
}) => {
  const { isLoaded } = useContext(GlobalContext)
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })

  const [{ st }, set] = useSpring(() => ({
    st: 0,
    immediate: true,
  }))
  const onScroll = useCallback(() => {
    set({ st: document.body.scrollTop || document.documentElement.scrollTop })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  let imagesPair = []

  return (
    <div
      ref={inViewRef}
      className={cs('header-padding-offset overflow-hidden', {
        'in-view': isLoaded && inView,
      })}
    >
      <div className={style.headerImageContainer}>
        <animated.div
          style={{
            transform: st.interpolate(
              o =>
                `matrix3d(${1 + o / 6000},0,0.00,0,0.00,${1 +
                  o / 6000},0.00,0,0,0,1,0,0,${o * 0.4},0,1)`
            ),
          }}
          className={style.headerImageInnerContainer}
        >
          <Img
            fluid={header_image.localFile.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            className={style.headerImage}
          />
        </animated.div>
      </div>
      <div className="relative container">
        {is_new_project && <div className={style.isNewBadge}>{year}</div>}
        <div className={cs('py-8 sm:py-12 max-w-2xl', style.textContainer)}>
          <div className="max-w-xl">
            <h1
              className="primary-appearance"
              style={{ transitionDelay: '350ms' }}
            >
              {title}
            </h1>
            <div
              className="primary-description primary-appearance"
              dangerouslySetInnerHTML={{ __html: description }}
              style={{ transitionDelay: '500ms' }}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-stretch justify-center -mx-4">
          {images &&
            images.map(
              ({
                localFile: {
                  childImageSharp: { fixed: image },
                },
              }) => {
                if (image.width < 1376) {
                  imagesPair.push(image)
                  return null
                }
                if (imagesPair.length > 1) {
                  const cols = imagesCols(imagesPair)
                  imagesPair = []
                  return cols
                }
                return (
                  <div className="w-full p-4">
                    <Img
                      fixed={image}
                      className={style.contentImage}
                      backgroundColor="#ebebec"
                    />
                  </div>
                )
              }
            )}
          {imagesCols(imagesPair)}
        </div>
      </div>
      <ContactForm
        title="Напишите прямо сейчас"
        className="md:h-screen header-padding-offset"
      />
    </div>
  )
}

export default ProjectPage
